<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
      <p class="mt-3">
        در این قسمت میتوانید گروه را مدیریت کنید و تظیمات دلخواه را انجام بدید
      </p>
    </template>
    <template #default>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <router-link
          v-for="(item, index) in options"
          :key="index"
          :to="item.link"
          class="
            p-3
            bg-blueGray-100
            border-blueGray-200
            hover:bg-blueGray-200
            border
            rounded-md
            flex
            items-center
          "
        >
          <div class="text-lg font-bold w-full">{{ item.text }}</div>
          <img :src="item.image" class="mx-2" />
        </router-link>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
  components: {
    BodySimple,
    Icon,
  },
  data() {
    return {
      // options: ,
      firstLoading: true,
      group: {},
    };
  },
  computed: {
    options() {
      return [
        {
          image: require("@/assets/images/icons8-adjustment-96.png"),
          text: "تنظیمات کاربران",
          link: {
            name: "GroupOwnerSettingUser",
            params: {
              id: this.$store.state.user.id,
              groupId: this.group?.id ?? 0,
              settingId: this.group?.defSetting?.id ?? 0,
            },
          },
        },
        {
          image: require("@/assets/images/icons8-settings-96.png"),
          text: "تنظیمات گروه",
          link: {
            name: "GroupOwnerSettingMain",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-group-message-96.png"),
          text: "پیام های ربات",
          link: {
            name: "GroupOwnerSettingBotPm",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-team-96.png"),
          text: "کاربران",
          link: {
            name: "GroupOwnerSettingShowUsers",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-admin-settings-male-96.png"),
          text: "مدیران",
          link: {
            name: "GroupOwnerSettingShowAdmins",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-money-transfer-96.png"),
          text: "درامد از گروه",
          link: {
            name: "GroupOwnerSettingIncome",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-open-envelope-clock-96.png"),
          text: "تکرار پست در گروه",
          link: {
            name: "GroupOwnerSettingRepeat",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-gears-96.png"),
          text: "مدیریت دسته‌بندی‌ها",
          link: {
            name: "GroupOwnerSettingBranchs",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-vip-96.png"),
          text: "گروه VIP",
          link: {
            name: "GroupOwnerSettingVip",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
        {
          image: require("@/assets/images/icons8-bot-96.png"),
          text: "دسترسی‌های ربات",
          link: {
            name: "GroupOwnerSettingBot",
            params: { id: this.$store.state.user.id, groupId: this.group?.id ?? 0 },
          },
        },
      ];
    },
  },
  mounted() {
    let $this = this;
    // console.log($this.$store.state.user.id);
    $this.$axios
      .get("/api/groups-owner/" + $this.$route.params.groupId, {
        params: {
          id: $this.$store.state.user.id,
        },
      })
      .then(function (response) {
        // console.log(response);
        $this.group = response.data.group;
      })
      .catch(function (error) {
        // console.log(error);
      })
      .finally(function () {
        // always executed
        $this.firstLoading = false;
      });
  },
};
</script>

<style></style>
